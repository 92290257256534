<template>
  <div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item,i) in videoList" :key="i+'video'" :style="{height: height}">
        <div class="cardVideo">
          <img class="cardPhone" src="@/assets/image/phoneke.png" alt="">
          <video-player ref="videoPlayer" class="video-player vjs-custom-skin video-self" :playsinline="true" style="object-fit:fill" webkit-playsinline='true' x5-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" :options="{
             autoplay: false,
             muted: false,
             loop: false,
             preload: 'auto',
             language: 'zh-CN',
             aspectRatio: '16:9',
             fluid: true,
             hls: true,
             notSupportedMessage: '此视频暂无法播放，请稍后再试',
             controlBar: {
                timeDivider: false,
                durationDisplay: false,
                remainingTimeDisplay: false,
                fullscreenToggle: false // 全屏按钮
              },
             sources: [
                {
                  type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                  src: item.url // url地址
                }
            ]
          }" @play="onPlayerPlay(i)" @pause="onPlayerPause(i)"/>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { isMobile } from '@/assets/js/fixed_data'

export default {
  name: "video_swiper",
  components: {
    swiper, swiperSlide
  },
  props: {
    videoList: Array,
    slidesPerView: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: isMobile ? 1 : this.slidesPerView,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    height() {
      return isMobile ? '4.4rem' : '4.6rem'
    }
  },
  methods: {
    onPlayerPlay(i) {
      this.$emit('otherStart', false)
      this.$refs.videoPlayer.forEach((item, index) => {
        if (index !== i) {
          item.player.pause();
        }
      })
    },
    onPlayerPause(e) {
      console.log(e)
    },
  }
}
</script>

<style scoped lang="less">
.cardVideo {
  width: 180px;
  height: 360px;
  margin: 0 auto;
  position: relative;

  .cardPhone {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .video-self {
    position: absolute;
    top: 10px;
    left: 11px;
    z-index: 11;
    width: 163px;
    height: 343px;
    border-radius: 17px;
    overflow: hidden;

    /deep/ .video-js.vjs-fluid {
      height: 100%;
    }
  }
}

/deep/ .vjs-custom-skin > .video-js .vjs-big-play-button {
  font-size: 14px;
}

/deep/ .vjs-custom-skin > .video-js .vjs-control-bar {
  visibility: hidden;
}

/deep/ .vjs-control-bar {
  display: none !important;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .videoBox {
    /*width: 7.2rem;*/
    height: 7.5rem;
    width: 100%;
    margin: 0 auto;

    .video-self {
      height: 100%;

      /deep/ .video-js {
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .cardVideo {
    //width: 104px;
    //height: 228px;
    margin: 0 auto;
    position: relative;

    .cardPhone {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    .video-self {
      position: absolute;
      top: 5px;
      left: 10px;
      //z-index: 11;
      //width: 174px;
      height: 350px;
      border-radius: 15px;
    }
  }

}
</style>
