var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.videoList),function(item,i){return _c('swiper-slide',{key:i+'video',style:({height: _vm.height})},[_c('div',{staticClass:"cardVideo"},[_c('img',{staticClass:"cardPhone",attrs:{"src":require("@/assets/image/phoneke.png"),"alt":""}}),_c('video-player',{ref:"videoPlayer",refInFor:true,staticClass:"video-player vjs-custom-skin video-self",staticStyle:{"object-fit":"fill"},attrs:{"playsinline":true,"webkit-playsinline":"true","x5-playsinline":"true","x-webkit-airplay":"true","x5-video-player-type":"h5","x5-video-player-fullscreen":"true","x5-video-ignore-metadata":"true","options":{
           autoplay: false,
           muted: false,
           loop: false,
           preload: 'auto',
           language: 'zh-CN',
           aspectRatio: '16:9',
           fluid: true,
           hls: true,
           notSupportedMessage: '此视频暂无法播放，请稍后再试',
           controlBar: {
              timeDivider: false,
              durationDisplay: false,
              remainingTimeDisplay: false,
              fullscreenToggle: false // 全屏按钮
            },
           sources: [
              {
                type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: item.url // url地址
              }
          ]
        }},on:{"play":function($event){return _vm.onPlayerPlay(i)},"pause":function($event){return _vm.onPlayerPause(i)}}})],1)])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }