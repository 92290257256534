<template>
  <div class="oneBox">
    <div class="twoBox">

      <el-row class="pshow" :gutter="150">
        <el-col :span="13" class="borderCol">
          <div class="leftBox">

            <u-animate-container>
              <u-animate
                name="fadeInLeft"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false">
                <div class="formTitle">
                  <div class="titleBox">
                    <div class="title1">
                      <span class="spanicon"></span>
                      <span>&nbsp;&nbsp;留下您的联系方式&nbsp;&nbsp;</span>
                      <span class="spanicon"></span>
                    </div>
                    <span class="title2">Leave your contact</span>
                  </div>
                </div>
                <div class="formBox" >
                  <el-form
                    hide-required-asterisk
                    :model="ruleForm"
                    :rules="rules"

                    ref="ruleForm">

                    <el-form-item prop="company">
                      <el-input v-model="ruleForm.company" placeholder="企业名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="name">
                      <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item prop="phone">
                      <el-input v-model="ruleForm.phone"  placeholder="手机"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button class="submitBtn" type="defult" round @click="submitForm('ruleForm')">预约顾问</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </u-animate>
            </u-animate-container>


            <div class="midborder"></div>
          </div>

        </el-col>
        <el-col :span="11">
          <u-animate-container>
            <u-animate
              name="fadeInRight"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <div class="imgBox">
                <p class="img_title">我们随时为你提供帮助</p>
                <img class="img_pic" :src="imgUrl"  alt="">
              </div>
            </u-animate>
          </u-animate-container>
        </el-col>
      </el-row>

      <u-animate-container>
        <u-animate
          class="mshow leftBox"
          name="fadeIn"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false">
          <div class="formTitle">
            <div class="titleBox">
              <div class="title1">
                <span class="spanicon"></span>
                <span>&nbsp;&nbsp;留下您的联系方式&nbsp;&nbsp;</span>
                <span class="spanicon"></span>
              </div>
              <span class="title2">Leave your contact</span>
            </div>
          </div>
          <div class="formBox" >
            <el-form
              hide-required-asterisk
              :model="ruleForm"
              :rules="rules"
              ref="mruleForm">

              <el-form-item prop="company">
                <el-input v-model="ruleForm.company" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item prop="name">
                <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input v-model="ruleForm.phone"  placeholder="手机"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="submitBtn" type="defult" round @click="submitForm('mruleForm')">预约顾问</el-button>
              </el-form-item>
            </el-form>
          </div>
        </u-animate>
      </u-animate-container>

    </div>
  </div>

</template>
<script>
  import { Notification } from 'element-ui';
  import {requestServices} from "../../api/api";
  export default {
    data(){
      let phoneCheck = (rule, value, callback) => {
        if(!value){
          callback(new Error("请输入手机号"))
        }else{
          if (!/^1[34578]\d{9}$/.test(value)) {
            callback(new Error("手机号码有误，请重新输入"))
          }else {
            callback();
          }
        }

      }
      return{
        imgUrl:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/11tu02%402x.png',
        ruleForm: {
          name: '',
          company: '',
          phone: '',
        },
        rules: {
          company: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
            { min: 1, max: 30, message: '长度限制在30字符以内', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 1, max: 30, message: '长度限制在30字符以内', trigger: 'blur' }
          ],
          phone: [
            { validator:phoneCheck, required: true, trigger: 'blur'},
            // { required: true, message: '请输入手机号', trigger: 'blur' },
          ],
        }
      }
    },
    methods:{
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            requestServices.sendEmail(this.ruleForm)
            .then(res=>{
              if(res.return_code===1000){
                this.$refs[formName].resetFields();

                this.$confirm('我们的顾问将在1-3个工作日内与您电话联系，请您保持电话畅通。', '预约成功', {
                  confirmButtonText: '知道了',
                  type: 'success',
                  showCancelButton:false,
                  customClass:"tipBox",
                  confirmButtonClass:'tipBoxBtn',
                  center: true,
                }).then(() => {

                })
              }else if(res.return_code===1103){
                Notification.warning({
                  title: '重复预约',
                  message: '联系方式已预约，请勿重复预约...',
                  duration:2000,
                });
              } else{
                Notification.error({
                  title: '系统异常',
                  message: '请稍后再试...',
                  duration:2000,
                });
              }
            })
          } else {
            // Notification.error({
            //   title: '提交失败',
            //   message: '请确认表单信息是否正确',
            //   duration:2000,
            // });
            return false;
          }
        });
      },
    }
  }
</script>
<style lang="less" scoped>
  .oneBox{
    background: #7271FF;
    height: 5rem;
    .twoBox{
      width: 10rem;
      margin: 0 auto;
      position: relative;
      .leftBox{
        text-align: center;
        .formTitle{

          .titleBox{
            text-align: center;
            padding: 0.7rem 0 0.38rem;
            font-size: 0;
            color:rgba(255,255,255,1);
            .title1{
              text-align: center;
              font-size:22px;
              font-weight:400;
              .spanicon{
                position: relative;
                top: 0;
                width: 8px;
                height: 8px;
                display: inline-block;
                border: 3px solid #a0b1d2ad;
                border-radius: 50%;
              }
            }
            .title2{
              margin-top: 0.1rem;
              display: inline-block;
              font-size:14px;
              font-weight:400;
              color:#b4b8c1;
            }
          }
        }
        .formBox{
          width: 2.8rem;
          margin: 0 auto;
        }
      }
      .imgBox{
        text-align: center;
        .img_title{
          font-size:18px;
          font-weight:400;
          color:rgba(255,255,255,1);
          margin: 1rem 0 .35rem;
        }
        .img_pic{
          height: 2.54rem;
          width: 2.54rem;
        }
      }
    }
  }
/deep/ .el-input__inner{
  background: none;
  height: .5rem;
  border-radius: 31px;
  padding: 0 .29rem;
}
.submitBtn{
  width: 2.8rem;
  background: rgba(255,255,255,1);
  font-size: 18px;
  font-weight: 400;
  color: #5B5AF3;
  letter-spacing: 3px;
}

  .midborder{
    border-right: 2px solid rgba(216,216,216,1);
    opacity: 0.5;
    height: 1.6rem;
    position: absolute;
    left: 6.2rem;
    top: 1.7rem;
  }

  /deep/ .el-input__inner{
    color: white;
  }


  .pshow{
    display: block;
  }
  .mshow{
    display: none;
  }

  @media screen and (min-width: 320px) and (max-width: 992px) {
    .pshow{
      display: none;
    }
    .mshow{
      display: block;
    }
    .oneBox {
      height: 4rem;
      min-height: 340px;
      .twoBox {
        width: 100%;
        .leftBox {
          .formTitle {
            .titleBox {
              padding: 0.3rem 0 0.18rem;
              .title1 {
                font-size: 20px;
                font-weight: 500;
              }
            }
          }
          .formBox {
            width: 2.4rem;
          }
        }
      }
    }
    .submitBtn{
      width: 100%;
      font-size: 16px;
    }
    /deep/ .el-input__inner{
      background: none;
      height: .45rem;
    }
  }

</style>
